<template>
  <div class="ideaWrapper">
    <section>
      <h1>“Forest”</h1>

      <p>“Forest” is a speculative look into plausible new tree species that could cover the earth in the future to maintain natural
        balance. These new species are the result of evolution of existing ones and mutation between them using a machine learning
        algorithm. These trees have qualities from multiple trees from different environments. That allows them to be more resilient to
        future environments and in some cases even migrate away from the environment if it gets too harsh.</p>

      <p>Most of my childhood I lived with my grandparents on a farm. They taught me how to treat nature and animals. Over time, I observed
        how our industrial activity has changed the landscape and affected animal populations in a largely negative way. In my childhood
        memories where I saw birds, green forests and deer-filled fields, now lay construction sites and silenced forest cuts. This has
        inspired me to think and create works about and around the nature of the future, the soundscapes and their sustainability.</p>

      <p>The art work engages with my growing interest in the application of AI and forests. Combining data collection of tree species from
        different environments ( deserts, rainforests, tundra, etc) and Machine Learning image-making techniques styleGans2. The result is a
        fictional collection of trees that proposes and simulates a future alteration of the planet biodiversity, with results that are
        often unimaginable, abstract and absurd.</p>

      <p>images:<br/>
        I created a custom dataset of 39 existing tree species. Starting with the ones that grow in my home country Latvia following with
        trees from where I'm located at the moment Washington state USA and continue into tundra, desserts, rainforest, and even underwater
        ending with rear tree species. I ended up with 120’000 new trees. The new trees were separated into 39 groups using unsupervised
        KMeans clustering algorithm that groups similar data points together and discovers underlying patterns. Afterwards I manually went
        over all tree groups and selected 1848 trees with the most interesting color palette, shape, and background.</p>

      <p>sounds:<br/>
        I used tree species and environments where they grow as keywords to build a custom soundscape dataset using Freesounds API. With
        Machine Learning WaveGan (unsupervised synthesis of raw-waveform audio) similarly to creating the trees I generated short samples of
        soundscapes for each new tree species. A tree species is manually selected for each soundscape by me imagining how the tree would
        move and interact with its environment.</p>

      <p><em>Mežš means a Forest in Latvian.</em></p>

      <p>The website mezs.ai is an interactive digital archive consisting of 39 new tree species and soundscapes that I have composed.
        Zooming in changes the soundscape and allows you to observe multiple trees closer. Clicking on one of the trees enlarges it and
        triggers the soundscape of that specific species.</p>

      <p><small>One channel video-projection, mouse, 2 speakers, computer.</small></p>
    </section>
  </div>
</template>

<style lang="scss">
  .ideaWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    z-index: 99;
    color: #fff;

    section {
      display: block;
      position: relative;
      margin: 0 auto;
      width: 50vw;
      max-width: 1024px;
      min-width: 275px;
      padding: 50px 0;

      p {
        letter-spacing: 1px;
        text-align: justify;
        text-justify: inter-word;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
</style>

<script>
    export default {
        name: "About"
    }
</script>
